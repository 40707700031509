const versesBucket = [
    `<p>Hébreux 12:22-24</p> Au contraire, vous vous êtes approchés du mont Sion, de la cité du Dieu vivant, la Jérusalem céleste, et ses dizaines de milliers d'anges en fête, de l'assemblée des premiers-nés inscrits dans le ciel. Vous vous êtes approchés de Dieu qui est le juge de tous, des esprits des justes parvenus à la perfection, de Jésus, qui est le médiateur d'une alliance nouvelle, et du sang purificateur porteur d’un meilleur message que celui d'Abel. (LSG)`,
`<p>Hebrews 12:22-24</p> But you have come to Mount Zion and to the city of the living God, the heavenly Jerusalem, to an innumerable company of angels, to the general assembly and church of the firstborn who are registered in heaven, to God the Judge of all, to the spirits of just men made perfect, to Jesus the Mediator of the new covenant, and to the blood of sprinkling that speaks better things than that of Abel. (NKJV)`,
`<p>Jean 1:50</p> Jésus lui répondit : « Parce que je t'ai dit que je t'ai vu sous le figuier, tu crois ? Tu verras de plus grandes choses que celles-ci. » (LSG)`,
`<p>John 1:50</p> Jesus answered and said to him, “Because I said to you, ‘I saw you under the fig tree,’ do you believe? You will see greater things than these.” (NKJV)`,
`<p>Jean 1:51</p> Il ajouta : « En vérité, en vérité, je vous le dis, vous verrez [désormais] le ciel ouvert et les anges de Dieu monter et descendre au-dessus du Fils de l'homme. » (LSG)`,
`<p>John 1:51</p> And He said to him, “Most assuredly, I say to you, hereafter you shall see heaven open, and the angels of God ascending and descending upon the Son of Man.” (NKJV)`,
`<p>Éphésiens 1:17</p> Je prie que le Dieu de notre Seigneur Jésus-Christ, le Père de gloire, vous donne un esprit de sagesse et de révélation qui vous le fasse connaître. (LSG)`,
`<p>Ephesians 1:17</p> that the God of our Lord Jesus Christ, the Father of glory, may give to you the spirit of wisdom and revelation in the knowledge of Him, (NKJV)`,
`<p>Éphésiens 1:18</p> Je prie qu'il illumine les yeux de votre cœur pour que vous sachiez quelle est l’espérance qui s'attache à son appel, quelle est la richesse de son glorieux héritage au milieu des saints (LSG)`,
`<p>Ephesians 1:18</p> the eyes of your understanding being enlightened; that you may know what is the hope of His calling, what are the riches of the glory of His inheritance in the saints, (NKJV)`,
`<p>Matthieu 17:1-2</p> Six jours après, Jésus prit avec lui Pierre, Jacques et son frère Jean, et il les conduisit à l'écart sur une haute montagne. Il fut transfiguré devant eux ; son visage resplendit comme le soleil et ses vêtements devinrent blancs comme la lumière.  (LSG)`,
`<p>Matthew 17:1-2</p> Now after six days Jesus took Peter, James, and John his brother, led them up on a high mountain by themselves; and He was transfigured before them. His face shone like the sun, and His clothes became as white as the light. (NKJV)`,
`<p>1 Corinthiens 13:12</p> Aujourd'hui nous voyons au moyen d'un miroir, de manière peu claire, mais alors nous verrons face à face ; aujourd'hui je connais partiellement, mais alors je connaîtrai complètement, tout comme j'ai été connu. (LSG)`,
`<p>1 Corinthians 13:12</p> For now we see in a mirror, dimly, but then face to face. Now I know in part, but then I shall know just as I also am known. (NKJV)`,
`<p>Hébreux 12:1</p> Nous donc aussi, puisque nous sommes entourés d'une si grande nuée de témoins, rejetons tout fardeau et le péché qui nous enveloppe si facilement, et courons avec persévérance l'épreuve qui nous est proposée. (LSG)`,
`<p>Hebrews 12:1</p> Therefore we also, since we are surrounded by so great a cloud of witnesses, let us lay aside every weight, and the sin which so easily ensnares us, and let us run with endurance the race that is set before us, (NKJV)`,
`<p>Hébreux 12:2</p> Faisons-le en gardant les regards sur Jésus, qui fait naître la foi et la mène à la perfection. En échange de la joie qui lui était réservée, il a souffert la croix en méprisant la honte qui s’y attachait et il s’est assis à la droite du trône de Dieu. (LSG)`,
`<p>Hebrews 12:2</p> looking unto Jesus, the [a]author and [b]finisher of our faith, who for the joy that was set before Him endured the cross, despising the shame, and has sat down at the right hand of the throne of God. (NKJV)`,
`<p>Jérémie 33:2-3</p> « Voici ce que dit l’Eternel qui met en œuvre ces événements, l'Eternel qui leur donne forme en les préparant, celui dont le nom est l'Eternel : Fais appel à moi et je te répondrai. Je te révélerai des réalités importantes et inaccessibles, des réalités que tu ne connais pas. (LSG)`,
`<p>Jeremiah 33:2-3</p> Thus says the Lord who made it, the Lord who formed it to establish it (the Lord is His name): ‘Call to Me, and I will answer you, and show you great and mighty things, which you do not know.’ (NKJV)`,
`<p>Ésaïe 25:7-8</p> Sur cette montagne, il détruira le voile qui est tendu sur tous les peuples, la couverture qui est déployée sur toutes les nations. *Il engloutira la mort pour toujours. Le Seigneur, l'Eternel, essuiera les larmes de tous les visages, il fera disparaître de la terre la honte de son peuple. Oui, l'Eternel l’a décrété. (LSG)`,
`<p>Isaiah 25:7-8</p> And He will destroy on this mountain
The surface of the covering cast over all people,
And the veil that is spread over all nations.
 He will swallow up death forever,
And the Lord God will wipe away tears from all faces;
The rebuke of His people
He will take away from all the earth;
For the Lord has spoken. (NKJV)`,
`<p>Luc 6:12</p> A cette époque-là, Jésus se retira sur la montagne pour prier ; il passa toute la nuit à prier Dieu. (LSG)`,
`<p>Luke 6:12
</p>  Now it came to pass in those days that He went out to the mountain to pray, and continued all night in prayer to God. (NKJV)`,
`<p>Apocalypse 1:9-12</p> Moi Jean, votre frère et votre compagnon dans la persécution, le royaume et la persévérance en Jésus-Christ, j'étais dans l'île appelée Patmos à cause de la parole de Dieu et du témoignage de Jésus-Christ. Je fus saisi par l'Esprit le jour du Seigneur et j'entendis derrière moi une voix forte comme le son d'une trompette. Elle disait : « Ce que tu vois, écris-le dans un livre et envoie-le aux sept Eglises : à Ephèse, à Smyrne, à Pergame, à Thyatire, à Sardes, à Philadelphie et à Laodicée. » Je me retournai pour savoir quelle était la voix qui me parlait. M’étant donc retourné, je vis sept chandeliers d'or, (LSG)`,
`<p>Revelation 1:9-12</p> I, John, [a]both your brother and companion in the tribulation and kingdom and patience of Jesus Christ, was on the island that is called Patmos for the word of God and for the testimony of Jesus Christ. I was in the Spirit on the Lord’s Day, and I heard behind me a loud voice, as of a trumpet, saying, “I am the Alpha and the Omega, the First and the Last,” and, “What you see, write in a book and send it to the seven churches which are in Asia: to Ephesus, to Smyrna, to Pergamos, to Thyatira, to Sardis, to Philadelphia, and to Laodicea.”
Then I turned to see the voice that spoke with me. And having turned I saw seven golden lampstands, (NKJV)`,
`<p>2 Corinthiens 12:1-4</p> Il faut se glorifier... Cela n'est pas bon. J'en viendrai néanmoins à des visions et à des révélations du Seigneur. Je connais un homme en Christ, qui fut, il y a quatorze ans, ravi jusqu'au troisième ciel (si ce fut dans son corps je ne sais, si ce fut hors de son corps je ne sais, Dieu le sait). Et je sais que cet homme (si ce fut dans son corps ou sans son corps je ne sais, Dieu le sait) fut enlevé dans le paradis, et qu'il entendit des paroles ineffables qu'il n'est pas permis à un homme d'exprimer. (LSG)`,
`<p>2 Corinthians 12:1-4
</p> It is doubtless not profitable for me to boast. I will come to visions and revelations of the Lord: I know a man in Christ who fourteen years ago—whether in the body I do not know, or whether out of the body I do not know, God knows—such a one was caught up to the third heaven. And I know such a man—whether in the body or out of the body I do not know, God knows how he was caught up into Paradise and heard inexpressible words, which it is not lawful for a man to utter. (NKJV)`,
`<p>Apocalypse 4:1</p> Après cela, je regardai et voici, une porte était ouverte dans le ciel. La première voix que j'avais entendue me parler avec la force d’une trompette dit alors : « Monte ici et je te ferai voir ce qui doit arriver par la suite. » (LSG)`,
`<p>Revelation 4:1
</p> After these things I looked, and behold, a door standing open in heaven. And the first voice which I heard was like a trumpet speaking with me, saying, “Come up here, and I will show you things which must take place after this. (NKJV)`,
`<p>Apocalypse 4:2</p> Aussitôt je fus saisi par l'Esprit. Et voici, il y avait un trône dans le ciel, et sur ce trône quelqu'un était assis. (LSG)`,
`<p>Revelation 4:2
</p>  Immediately I was in the Spirit; and behold, a throne set in heaven, and One sat on the throne.
 (NKJV)`,
`<p>Apocalypse 17:3</p> Alors il me transporta en esprit dans un désert et je vis une femme assise sur une bête écarlate, couverte de noms blasphématoires et qui avait sept têtes et dix cornes. (LSG)`,
`<p>Revelation 17:3
</p> So he carried me away in the Spirit into the wilderness. And I saw a woman sitting on a scarlet beast which was full of names of blasphemy, having seven heads and ten horns. (NKJV)`,
`<p>Apocalypse 21:10</p> Il me transporta en esprit sur une grande et haute montagne et me montra la ville sainte, Jérusalem, qui descendait du ciel, d'auprès de Dieu. (LSG)`,
`<p>Revelation 21:10
</p> And he carried me away in the Spirit to a great and high mountain, and showed me the great city, the holy Jerusalem, descending out of heaven from God, (NKJV)`,
`<p>Ézéchiel 8:3</p> Il étendit une forme de main, et me saisit par les cheveux de la tête. L'esprit m'enleva entre la terre et le ciel, et me transporta, dans des visions divines, à Jérusalem, à l'entrée de la porte intérieure, du côté du septentrion, où était l'idole de la jalousie, qui excite la jalousie de l'Éternel. (LSG)`,
`<p>Ezekiel 8:3</p>  He stretched out the form of a hand, and took me by a lock of my hair; and the Spirit lifted me up between earth and heaven, and brought me in visions of God to Jerusalem, to the door of the north gate of the inner court, where the seat of the image of jealousy was, which provokes to jealousy. (NKJV)`,
`<p>Ézéchiel 11:1</p> L'Esprit m’a enlevé et m’a transporté à la porte de la maison de l'Eternel qui est à l’est, celle qui est orientée vers l’est. A l’entrée, il y avait 25 hommes. Au milieu d'eux, j’ai remarqué Jaazania, le fils d'Azzur, et Pelathia, le fils de Benaja. C’étaient des chefs du peuple. (LSG)`,
`<p>Ezekiel 11:1</p> Then the Spirit lifted me up and brought me to the East Gate of the Lord’s house, which faces eastward; and there at the door of the gate were twenty-five men, among whom I saw Jaazaniah the son of Azzur, and Pelatiah the son of Benaiah, princes of the people. (NKJV)`,
`<p>Ézéchiel 37:1</p> La main de l'Eternel reposait sur moi et l'Eternel m’a transporté par son Esprit pour me déposer au milieu de la vallée. Celle-ci était remplie d'ossements. (LSG)`,
`<p>Ezekiel 37:1</p> The hand of the Lord came upon me and brought me out in the Spirit of the Lord, and set me down in the midst of the valley; and it was full of bones. (NKJV)`,
`<p>Jean 9:39</p> Puis Jésus dit : « Je suis venu dans ce monde pour un jugement, pour que ceux qui ne voient pas voient et pour que ceux qui voient deviennent aveugles. » (LSG)`,
`<p>John 9:39</p> The hand of the Lord came upon me and brought me out in the Spirit of the Lord, and set me down in the midst of the valley; and it was full of bones. (NKJV)`,
`<p>Proverbes 4:18</p> Le sentier des justes ressemble à la lumière de l’aube : son éclat grandit jusqu'au milieu du jour. (LSG)`,
`<p>Proverbs 4:18</p> But the path of the just is like the shining sun,
That shines ever brighter unto the perfect day. (NKJV)`,
`<p>Marc 8:22-25</p> Ils se rendirent à Bethsaïda ; on amena un aveugle vers Jésus et on le supplia de le toucher. Il prit l'aveugle par la main et le conduisit à l’extérieur du village ; puis il lui mit de la salive sur les yeux, posa les mains sur lui et lui demanda s'il voyait quelque chose. Il regarda et dit : « J'aperçois les gens, je les vois comme des arbres, et ils marchent. » Jésus lui mit de nouveau les mains sur les yeux et, quand l'aveugle regarda fixement, il fut guéri et vit tout distinctement. (LSG)`,
`<p>Mark 8:22-25</p> Then He came to Bethsaida; and they brought a blind man to Him, and begged Him to touch him. 23 So He took the blind man by the hand and led him out of the town. And when He had spit on his eyes and put His hands on him, He asked him if he saw anything.

24 And he looked up and said, “I see men like trees, walking.” (NKJV)`,
`<p>Exode 25:40</p> *Regarde et fais tout d'après le modèle qui t'est montré sur la montagne. (LSG)`,
`<p>Exodus 25:40</p> And see to it that you make them according to the pattern which was shown you on the mountain. (NKJV)`,
`<p>Hébreux 11:3</p> Par la foi, nous comprenons que l’univers a été formé par la parole de Dieu, de sorte que le monde visible n’a pas été fait à partir des choses visibles. (LSG)`,
`<p>Hebrews 11:3</p> By faith we understand that the [a]worlds were framed by the word of God, so that the things which are seen were not made of things which are visible. (NKJV)`,
`<p>Hébreux 8:5</p> Or, ils célèbrent un culte qui n’est que la copie et l'ombre des réalités célestes. Moïse en avait été averti alors qu’il allait construire le tabernacle : Regarde, lui dit en effet le Seigneur, et fais tout d'après le modèle qui t'a été montré sur la montagne. (LSG)`,
`<p>Hebrews 8:5</p> who serve the copy and shadow of the heavenly things, as Moses was divinely instructed when he was about to make the tabernacle. For He said, “See that you make all things according to the pattern shown you on the mountain.” (NKJV)`,
`<p>Jean 5:19</p> Jésus reprit donc la parole et leur dit : « En vérité, en vérité, je vous le dis, le Fils ne peut rien faire de lui-même, sinon ce qu'il voit le Père accomplir. Tout ce que le Père fait, le Fils aussi le fait pareillement. (LSG)`,
`<p>John 5:19</p> Then Jesus answered and said to them, “Most assuredly, I say to you, the Son can do nothing of Himself, but what He sees the Father do; for whatever He does, the Son also does in like manner. (NKJV)`,
`<p>Jean 5:20</p> En effet, le Père aime le Fils et lui montre tout ce que lui-même fait, et il lui montrera des œuvres plus grandes que celles-ci, afin que vous soyez dans l'étonnement. (LSG)`,
`<p>John 5:20</p> For the Father loves the Son, and shows Him all things that He Himself does; and He will show Him greater works than these, that you may marvel. (NKJV)`,
`<p>Actes 26:16</p> Mais lève-toi et tiens-toi debout, car je te suis apparu pour faire de toi le serviteur et le témoin de ce que tu as vu et de ce que je te montrerai. (LSG)`,
`<p>Acts 26:16</p> But rise and stand on your feet; for I have appeared to you for this purpose, to make you a minister and a witness both of the things which you have seen and of the things which I will yet reveal to you. (NKJV)`,
`<p>Apocalypse 3:18</p> Je te conseille donc d'acheter chez moi de l'or purifié par le feu afin que tu deviennes vraiment riche, des vêtements blancs afin que tu sois habillé et qu'on ne voie plus la honte de ta nudité, ainsi qu’un remède à appliquer sur tes yeux afin que tu voies. (LSG)`,
`<p>Revelation 3:18
</p>  counsel you to buy from Me gold refined in the fire, that you may be rich; and white garments, that you may be clothed, that the shame of your nakedness may not be revealed; and anoint your eyes with eye salve, that you may see. (NKJV)`,
`<p>1 Rois 19:7</p> L'ange de l'Eternel vint une deuxième fois, le toucha et dit : « Lève-toi et mange, car le chemin est trop long pour toi. » (LSG)`,
`<p>1 Kings 19:7</p> And the angel of the Lord came back the second time, and touched him, and said, “Arise and eat, because the journey is too great for you.” (NKJV)`,
`<p>Joël 2:28</p> Après cela, je répandrai mon esprit sur toute chair; Vos fils et vos filles prophétiseront, Vos vieillards auront des songes, Et vos jeunes gens des visions. (LSG)`,
`<p>Joel 2:28
</p> “And it shall come to pass afterward
That I will pour out My Spirit on all flesh;
Your sons and your daughters shall prophesy,
Your old men shall dream dreams,
Your young men shall see visions. (NKJV)`,
`<p>Ésaïe 6:8</p> J'ai entendu le Seigneur dire : « Qui vais-je envoyer et qui va marcher pour nous ? » J’ai répondu : « Me voici, envoie-moi ! » (LSG)`,
`<p>Isaiah 6:8
</p> Also I heard the voice of the Lord, saying:
“Whom shall I send,
And who will go for Us?”
Then I said, “Here am I! Send me.” (NKJV)`,
`<p>Romains 12:1</p> Je vous encourage donc, frères et sœurs, par les compassions de Dieu, à offrir votre corps comme un sacrifice vivant, saint, agréable à Dieu. Ce sera de votre part un culte raisonnable. (LSG)`,
`<p>Romans 12:1
</p> I beseech you therefore, brethren, by the mercies of God, that you present your bodies a living sacrifice, holy, acceptable to God, which is your reasonable service. (NKJV)`,
`<p>Jean 14:18-19</p> Je ne vous laisserai pas orphelins, je reviens vers vous. Encore un peu de temps et le monde ne me verra plus ; mais vous, vous me verrez parce que je vis et que vous vivrez aussi. (LSG)`,
`<p>John 14:18-19
</p> I will not leave you orphans; I will come to you.
 “A little while longer and the world will see Me no more, but you will see Me. Because I live, you will live also. (NKJV)`,
`<p>Jean 14:21</p> Celui qui a mes commandements et qui les garde, c'est celui qui m'aime ; celui qui m'aime sera aimé de mon Père et moi aussi, je l'aimerai et je me ferai connaître à lui. » (LSG)`,
`<p>John 14:21
</p> He who has My commandments and keeps them, it is he who loves Me. And he who loves Me will be loved by My Father, and I will love him and manifest Myself to him.”
 (NKJV)`,
`<p>Jean 14:23</p> Jésus lui répondit : « Si quelqu'un m'aime, il gardera ma parole et mon Père l'aimera ; nous viendrons vers lui et nous établirons domicile chez lui. (LSG)`,
`<p>John 14:23
</p> Jesus answered and said to him, “If anyone loves Me, he will keep My word; and My Father will love him, and We will come to him and make Our home with him.
 (NKJV)`,
`<p>Jean 14:17</p> l'Esprit de la vérité, que le monde ne peut pas accepter parce qu'il ne le voit pas et ne le connaît pas. [Mais] vous, vous le connaissez, car il reste avec vous et il sera en vous. (LSG)`,
`<p>John 14:17
</p> the Spirit of truth, whom the world cannot receive, because it neither sees Him nor knows Him; but you know Him, for He dwells with you and will be in you. (NKJV)`,
`<p>Actes 2:25</p> En effet, David dit à propos de lui : Je voyais constamment le Seigneur devant moi, parce qu'il est à ma droite afin que je ne sois pas ébranlé. (LSG)`,
`<p>Acts 2:25
</p> For David says concerning Him:
‘I foresaw the Lord always before my face,
For He is at my right hand, that I may not be shaken. (NKJV)`,
`<p>Actes 8:39</p> Quand ils furent sortis de l'eau, l'Esprit du Seigneur enleva Philippe et l'eunuque ne le vit plus. Il poursuivit sa route tout joyeux. (LSG)`,
`<p>Acts 8:39
</p> Now when they came up out of the water, the Spirit of the Lord caught Philip away, so that the eunuch saw him no more; and he went on his way rejoicing.
 (NKJV)`,
`<p>Actes 8:26-28</p> Un ange du Seigneur s'adressa à Philippe en disant : « Lève-toi et va en direction du sud, sur le chemin qui descend de Jérusalem à Gaza, celui qui est désert. » Il se leva et partit. Or un eunuque éthiopien, haut fonctionnaire de Candace, la reine d'Ethiopie, et administrateur de tous ses trésors, était venu à Jérusalem pour adorer. Il repartait, assis sur son char, et lisait le prophète Esaïe. (LSG)`,
`<p>Acts 8:26-28
</p>  Now an angel of the Lord spoke to Philip, saying, “Arise and go toward the south along the road which goes down from Jerusalem to Gaza.” This is desert.  So he arose and went. And behold, a man of Ethiopia, a eunuch of great authority under Candace the queen of the Ethiopians, who had charge of all her treasury, and had come to Jerusalem to worship, was returning. And sitting in his chariot, he was reading Isaiah the prophet.
 (NKJV)`,
`<p>Actes 8:29-31</p> L'Esprit dit à Philippe : « Avance et approche-toi de ce char. » Philippe accourut et entendit l'Ethiopien lire le prophète Esaïe. Il lui dit : « Comprends-tu ce que tu lis ? » L’homme répondit : « Comment le pourrais-je, si personne ne me l'explique ? » et invita Philippe à monter et à s'asseoir avec lui. (LSG)`,
`<p>Acts 8:29-31
</p> Then the Spirit said to Philip, “Go near and overtake this chariot.”
30 So Philip ran to him, and heard him reading the prophet Isaiah, and said, “Do you understand what you are reading?”
31 And he said, “How can I, unless someone guides me?” And he asked Philip to come up and sit with him. (NKJV)`,
`<p>1 Rois 3:3-5</p> Salomon aimait l'Eternel, suivant ainsi les prescriptions de son père David. Toutefois, c'était sur les hauts lieux qu'il offrait des sacrifices et des parfums. Le roi se rendit à Gabaon pour y offrir des sacrifices, car c'était le principal des hauts lieux. Salomon offrit 1000 holocaustes sur l'autel. A Gabaon, l'Eternel lui apparut en rêve pendant la nuit et Dieu lui dit : « Demande-moi ce que tu veux que je te donne. » (LSG)`,
`<p>1 Kings 3:3-5
</p> And Solomon loved the Lord, walking in the statutes of his father David, except that he sacrificed and burned incense at the high places.
Now the king went to Gibeon to sacrifice there, for that was the great high place: Solomon offered a thousand burnt offerings on that altar. At Gibeon the Lord appeared to Solomon in a dream by night; and God said, “Ask! What shall I give you?” (NKJV)`,
`<p>2 Corinthiens 6:16</p> Quel rapport peut-il y avoir entre le temple de Dieu et les idoles ? En effet, vous êtes le temple du Dieu vivant, comme Dieu l'a dit : J'habiterai et je marcherai au milieu d'eux ; je serai leur Dieu et ils seront mon peuple. (LSG)`,
`<p>2 Corinthians 6:16
</p> And what agreement has the temple of God with idols? For you are the temple of the living God. As God has said:
“I will dwell in them
And walk among them.
I will be their God,
And they shall be My people.” (NKJV)`,
`<p>Exode 33:19-21</p> L'Eternel répondit : « Je ferai passer devant toi toute ma bonté et je proclamerai devant toi le nom de l'Eternel. *Je fais grâce à qui je veux faire grâce, et j’ai compassion de qui je veux avoir compassion. » Il ajouta : « Tu ne pourras pas voir mon visage, car l'homme ne peut me voir et vivre. » L'Eternel dit : « Voici un endroit près de moi. Tu te tiendras sur le rocher. (LSG)`,
`<p>Exodus 33:19-21
</p>  Then He said, “I will make all My goodness pass before you, and I will proclaim the name of the Lord before you. I will be gracious to whom I will be gracious, and I will have compassion on whom I will have compassion.”  But He said, “You cannot see My face; for no man shall see Me, and live.”  And the Lord said, “Here is a place by Me, and you shall stand on the rock.
 (NKJV)`,
`<p>Daniel 2:19</p> C’est alors que le secret fut révélé à Daniel dans une vision pendant la nuit. Il bénit le Dieu du ciel (LSG)`,
`<p>Daniel 2:19
</p> Then the secret was revealed to Daniel in a night vision. So Daniel blessed the God of heaven. (NKJV)`,
`<p>Psaume 25:14</p> L’Eternel confie ses secrets à ceux qui le craignent, il leur fait connaître son alliance. (LSG)`,
`<p>Psalm 25:14
</p> The secret of the Lord is with those who fear Him,
And He will show them His covenant. (NKJV)`,
`<p>Ésaïe 50:4-5</p> Le Seigneur, l'Eternel, m’a donné le langage des disciples pour que je sache soutenir par la parole celui qui est abattu. Il réveille, oui, matin après matin il réveille mon oreille pour que j'écoute comme le font des disciples. Le Seigneur, l'Eternel, m'a ouvert l'oreille, et moi, je ne me suis pas rebellé, je n’ai pas reculé. (LSG)`,
`<p>Isaiah 50:4-5
</p> “The Lord God has given Me
The tongue of the learned,
That I should know how to speak
A word in season to him who is weary.
He awakens Me morning by morning,
He awakens My ear
To hear as the learned. The Lord God has opened My ear;
And I was not rebellious, (NKJV)`,
`<p>Actes 12:5-7</p> Pierre était donc gardé dans la prison et l'Eglise adressait d'ardentes prières à Dieu pour lui. La nuit qui précédait le jour où Hérode allait le faire comparaître, Pierre, attaché avec deux chaînes, dormait entre deux soldats ; des sentinelles postées devant la porte gardaient la prison. Soudain, un ange du Seigneur survint et une lumière resplendit dans la cellule. L'ange réveilla Pierre en le frappant au côté et lui dit : « Lève-toi vite ! » Les chaînes tombèrent de ses mains. (LSG)`,
`<p>Acts 12:5-7
</p> Peter was therefore kept in prison, but constant prayer was offered to God for him by the church. And when Herod was about to bring him out, that night Peter was sleeping, bound with two chains between two soldiers; and the guards before the door were [b]keeping the prison. Now behold, an angel of the Lord stood by him, and a light shone in the prison; and he struck Peter on the side and raised him up, saying, “Arise quickly!” And his chains fell off his hands. (NKJV)`,
`<p>Actes 10:19-21</p> Pierre réfléchissait encore à la vision quand l'Esprit lui dit : « Il y a trois hommes qui te cherchent. Lève-toi, descends et pars avec eux sans hésiter, car c'est moi qui les ai envoyés. » Pierre descendit et dit à ces hommes : « Me voici, je suis celui que vous cherchez. Quelle est la raison qui vous amène ici ? » (LSG)`,
`<p>Acts 10:19-21
</p>  While Peter thought about the vision, the Spirit said to him, “Behold, three men are seeking you.  Arise therefore, go down and go with them, doubting nothing; for I have sent them.”
Then Peter went down to the men [a]who had been sent to him from Cornelius, and said, “Yes, I am he whom you seek. For what reason have you come?” (NKJV)`,
`<p>1 Pierre 2:2-5</p> et comme des enfants nouveau-nés désirez le lait pur de la parole. Ainsi, grâce à lui vous grandirez [pour le salut], si du moins vous avez goûté que le Seigneur est bon. Approchez-vous de Christ, la pierre vivante rejetée par les hommes mais choisie et précieuse devant Dieu, et vous-mêmes, en tant que pierres vivantes, laissez-vous édifier pour former une maison spirituelle, un groupe de prêtres saints, afin d'offrir des sacrifices spirituels que Dieu peut accepter par Jésus-Christ. (LSG)`,
`<p>1 Peter 2:2-5
</p> as newborn babes, desire the pure milk of the word, that you may grow thereby,  if indeed you have tasted that the Lord is gracious.
 Coming to Him as to a living stone, rejected indeed by men, but chosen by God and precious,  you also, as living stones, are being built up a spiritual house, a holy priesthood, to offer up spiritual sacrifices acceptable to God through Jesus Christ. (NKJV)`,
`<p>Colossiens 3:1</p> Si donc vous êtes ressuscités avec Christ, recherchez les choses d'en haut, où Christ est assis à la droite de Dieu. (LSG)`,
`<p>Colossians 3:1
</p> If then you were raised with Christ, seek those things which are above, where Christ is, sitting at the right hand of God. (NKJV)`,
`<p>Colossiens 3:2</p> Attachez-vous aux réalités d'en haut, et non à celles qui sont sur la terre. (LSG)`,
`<p>Colossians 3:2
</p> Set your mind on things above, not on things on the earth.
 (NKJV)`,
`<p>Romains 4:18-20</p> Sans faiblir dans la foi, il n’a pas considéré que son corps était déjà usé, puisqu'il avait près de 100 ans, ni que Sara n'était plus en état d'avoir des enfants. Il n’a pas douté, par incrédulité, de la promesse de Dieu, mais il a été fortifié par la foi et il a rendu gloire à Dieu, car il avait la pleine conviction que ce que Dieu promet, il peut aussi l'accomplir. (LSG)`,
`<p>Romans 4:18-20
</p> who, contrary to hope, in hope believed, so that he became the father of many nations, according to what was spoken, “So shall your descendants be.”  And not being weak in faith, he did not consider his own body, already dead (since he was about a hundred years old), and the deadness of Sarah’s womb.  He did not waver at the promise of God through unbelief, but was strengthened in faith, giving glory to God, (NKJV)`,
`<p>Marc 1:35</p> Vers le matin, alors qu'il faisait encore très sombre, il se leva et sortit pour aller dans un endroit désert où il pria. (LSG)`,
`<p>Mark 1:35
</p> Now in the morning, having risen a long while before daylight, He went out and departed to a solitary place; and there He prayed. (NKJV)`,
`<p>2 Rois 6:16</p> Il répondit : « N’aie pas peur, car ceux qui sont avec nous sont plus nombreux que ceux qui sont avec eux. » (LSG)`,
`<p>2 Kings 6:16
</p> So he answered, “Do not fear, for those who are with us are more than those who are with them.” (NKJV)`,
`<p>2 Rois 6:17</p> Puis Elisée pria : « Eternel, ouvre ses yeux pour qu'il voie. » L'Eternel ouvrit les yeux du serviteur, et il vit la montagne pleine de chevaux et de chars de feu tout autour d'Elisée. (LSG)`,
`<p>2 Kings 6:17
</p> And Elisha prayed, and said, “Lord, I pray, open his eyes that he may see.” Then the Lord opened the eyes of the young man, and he saw. And behold, the mountain was full of horses and chariots of fire all around Elisha. (NKJV)`,
`<p>2 Rois 6:18</p> Les Syriens descendirent vers Elisée. Il adressa alors cette prière à l'Eternel : « Veuille frapper cette nation d'aveuglement ! » Et l'Eternel les frappa d'aveuglement, conformément à la parole d'Elisée. (LSG)`,
`<p>2 Kings 6:18
</p> So when the Syrians came down to him, Elisha prayed to the Lord, and said, “Strike this people, I pray, with blindness.” And He struck them with blindness according to the word of Elisha. (NKJV)`,
`<p>Jean 14:26</p> mais le défenseur, l'Esprit saint que le Père enverra en mon nom, vous enseignera toutes choses et vous rappellera tout ce que je vous ai dit. (LSG)`,
`<p>John 14:26
</p> But the Helper, the Holy Spirit, whom the Father will send in My name, He will teach you all things, and bring to your remembrance all things that I said to you. (NKJV)`,
`<p>Éphésiens 2:19</p> Ainsi donc, vous n'êtes plus des étrangers ni des résidents temporaires ; vous êtes au contraire concitoyens des saints, membres de la famille de Dieu. (LSG)`,
`<p>Ephesians 2:19
</p> Now, therefore, you are no longer strangers and foreigners, but fellow citizens with the saints and members of the household of God, (NKJV)`,
`<p>Philippiens 3:20</p> Quant à nous, notre droit de cité est dans le ciel, d'où nous attendons aussi comme Sauveur le Seigneur Jésus-Christ. (LSG)`,
`<p>Philippians 3:20
</p> For our citizenship is in heaven, from which we also eagerly wait for the Savior, the Lord Jesus Christ, (NKJV)`,
`<p>Ézéchiel 1:1</p> La trentième année, le cinquième jour du quatrième mois, je faisais partie des exilés, près du fleuve Kebar. Le ciel s’est ouvert et j’ai eu des visions divines. (LSG)`,
`<p>Ezekiel 1:1
</p>  Now it came to pass in the thirtieth year, in the fourth month, on the fifth day of the month, as I was among the captives by the River Chebar, that the heavens were opened and I saw visions of God. (NKJV)`,
`<p>Actes 7:56</p> Il dit : « Je vois le ciel ouvert et le Fils de l'homme debout à la droite de Dieu. » (LSG)`,
`<p>Acts 7:56
</p> and said, “Look! I see the heavens opened and the Son of Man standing at the right hand of God!” (NKJV)`,
`<p>Apocalypse 19:11</p> Ensuite, je vis le ciel ouvert, et voici qu’un cheval blanc apparut. Celui qui le montait s'appelle « Fidèle et Véritable », il juge et combat avec justice. (LSG)`,
`<p>Revelation 19:11
</p> Now I saw heaven opened, and behold, a white horse. And He who sat on him was called Faithful and True, and in righteousness He judges and makes war. (NKJV)`,
`<p>Luc 22:43</p> [Alors un ange lui apparut du ciel pour le fortifier. (LSG)`,
`<p>Luke 22:43
</p> Then an angel appeared to Him from heaven, strengthening Him. (NKJV)`,
`<p>Actes 27:23-24</p> En effet, un ange du Dieu auquel j'appartiens et que je sers m'est apparu cette nuit et m'a dit : ‘Paul, n’aie pas peur ! Il faut que tu comparaisses devant l'empereur, et voici que Dieu t'accorde la vie de tous ceux qui naviguent avec toi.’ (LSG)`,
`<p>Acts 27:23-24
</p>  For there stood by me this night an angel of the God to whom I belong and whom I serve,  saying, ‘Do not be afraid, Paul; you must be brought before Caesar; and indeed God has granted you all those who sail with you.’ (NKJV)`,
`<p>Actes 18:9-10</p> Le Seigneur dit à Paul dans une vision pendant la nuit : « N’aie pas peur, mais parle et ne te tais pas, car je suis moi-même avec toi et personne ne s’attaquera à toi pour te faire du mal. En effet, j'ai un peuple nombreux dans cette ville. » (LSG)`,
`<p>Acts 18:9-10
</p> Now the Lord spoke to Paul in the night by a vision, “Do not be afraid, but speak, and do not keep silent;  for I am with you, and no one will attack you to hurt you; for I have many people in this city.” (NKJV)`,
`<p>2 Corinthiens 3:2-4</p> C'est vous qui êtes notre lettre, écrite dans notre cœur, connue et lue de tous les hommes. Il est clair que vous êtes une lettre de Christ écrite par notre ministère, non avec de l'encre mais avec l'Esprit du Dieu vivant, non sur des tables de pierre mais sur des tables de chair, sur les cœurs. Telle est l'assurance que nous avons par Christ auprès de Dieu. (LSG)`,
`<p>2 Corinthians 3:2-4
</p> You are our epistle written in our hearts, known and read by all men; clearly you are an epistle of Christ, ministered by us, written not with ink but by the Spirit of the living God, not on tablets of stone but on tablets of flesh, that is, of the heart.
 And we have such trust through Christ toward God. (NKJV)`,
`<p>Jean 11:40</p> Jésus lui dit : « Ne t'ai-je pas dit que si tu crois, tu verras la gloire de Dieu ? » (LSG)`,
`<p>John 11:40
</p> Jesus said to her, “Did I not say to you that if you would believe you would see the glory of God?” (NKJV)`,
`<p>Aggée 2:9</p> La gloire de ce dernier temple sera plus grande que celle du premier, dit l'Eternel, le maître de l’univers, et c'est dans ce lieu que je donnerai la paix, déclare l'Eternel, le maître de l’univers.’ » (LSG)`,
`<p>Haggai 2:9
</p> The glory of this latter temple shall be greater than the former,’ says the Lord of hosts. ‘And in this place I will give peace,’ says the Lord of hosts.” (NKJV)`,
`<p>Genèse 18:17-19</p> Alors l'Eternel dit : « Cacherai-je à Abraham ce que je vais faire ? Abraham deviendra une nation grande et puissante, et toutes les nations de la terre seront bénies en lui. En effet, je l'ai choisi afin qu'il ordonne à ses fils et à sa famille après lui de garder la voie de l'Eternel en pratiquant la droiture et la justice. Ainsi l'Eternel accomplira en faveur d'Abraham les promesses qu'il lui a faites. » (LSG)`,
`<p>Genesis 18:17-19
</p> And the Lord said, “Shall I hide from Abraham what I am doing,  since Abraham shall surely become a great and mighty nation, and all the nations of the earth shall be blessed in him?  For I have known him, in order that he may command his children and his household after him, that they keep the way of the Lord, to do righteousness and justice, that the Lord may bring to Abraham what He has spoken to him.” (NKJV)`,
`<p>Colossiens 1:26-27</p> le mystère caché de tout temps et à toutes les générations, mais révélé maintenant à ses saints. En effet, Dieu a voulu leur faire connaître la glorieuse richesse de ce mystère parmi les non-Juifs, c’est-à-dire Christ en vous, l'espérance de la gloire. (LSG)`,
`<p>Colossians 1:26-27
</p>  the mystery which has been hidden from ages and from generations, but now has been revealed to His saints. To them God willed to make known what are the riches of the glory of this mystery among the Gentiles: which is Christ in you, the hope of glory.

 (NKJV)`
];
export default versesBucket;