const formData = require('form-data');
const Mailgun = require('mailgun.js');
const mailgun = new Mailgun(formData);

export async function sendVerseToEmail(toEmail, verse, hasSubscribed) {


    const removeHTMLTags = function(text) {
        return text.replace(/<\/?[^>]+(>|$)/g, "");
    };

    const DOMAIN = 'mg.mpen-international.com';
    const api_key = '331bcffb0926116f503558f18ee0f4e7-c50a0e68-9fb4ea9e';
    const mg = mailgun.client({
        username: 'api',
        key: process.env.MAILGUN_API_KEY || api_key,
        public_key: process.env.MAILGUN_PUBLIC_KEY || 'pubkey-b373d88ece2f713bf201c9412488cb33'
    });
    const verseFR = removeHTMLTags(verse.verseFR);
    const verseEN = removeHTMLTags(verse.verseEN);
    const wishesFR = 'Bonne année 2024 !!!'
    const wishesEN = 'Happy new year 2024 !!!'
    const yourVerseFRDescription = 'Ton verset de l\'année 2024'
    const yourVerseENDescription = 'Here is Your verse of the year 2024'
    const data = {
        from: 'Verses MPEN-INTERNATIONAL <contact@mpen-international.com>',
        to: `${toEmail}`,
        subject: `Your verse of the year 2024 | Ton verset de l'année 2024 `,
        template: 'verseoftheyear',
        'h:X-Mailgun-Variables': `{"verseFR": "${verseFR} ", "verseEN": "${verseEN} ", 
        "yourVerseFRDescription": "${yourVerseFRDescription}", "yourVerseENDescription": "${yourVerseENDescription}",
        "wishesFR": "${wishesFR}"}, "wishesEN": "${wishesEN}"}`
    };

    await mg.messages.create(DOMAIN, data);


    //Adding subscribed email to mailchimp
    console.log('has subscribed ' + hasSubscribed);
    if (hasSubscribed === true) {

        //Fast solution
        const dataSubscribed = {
            from: 'Verses MPEN-INTERNATIONAL <contact@mpen-international.com>',
            to: `mpensubscriptions@gmail.com`,
            subject: `New Subscription Throught Verse Of the Year`,
            text: toEmail,
        };

        mg.messages.create(DOMAIN, dataSubscribed).then(msg => console.log(msg)).catch(err => console.log(err));
    }
}