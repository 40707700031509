export function getRandomVerse(arr, not) {
  const random =  Math.floor(Math.random() * arr.length);
  const indexVerseFR =  random % 2 ===0  ? random : random - 1; 
  const indexVerseEN  =  indexVerseFR+1;
  console.log("FR index  =  " + indexVerseFR);
  console.log("EN index  =  " + indexVerseEN);
  console.log(`Total verses: ${arr.length/2}`);
  const verseFR = arr[indexVerseFR];
  const verseEN = arr[indexVerseEN];
  if (verseFR === not) {
    console.log('Ahh we used that one last time, look again');
    return getRandomVerse(arr, not);
  }
  return {verseFR, verseEN};
}


