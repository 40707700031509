import { verseHolder, hiddenVerseHolder, emailError, emailInput } from './elements.js';
import { getRandomVerse } from './utils.js';
import versesBucket from '../data/versesBucket.js';
import {sendVerseToEmail} from './email.js'

export async function handleClick() {

  const regexEmailValidation =  /\S+@\S+\.\S+/;
  const email = emailInput.value;
  const checkbox = document.querySelector("#check_newsletters").checked;
  
  if(email && regexEmailValidation.test(email)) {

    emailInput.classList.remove('error');
    emailError.style.visibility= "hidden";

    const verse = getRandomVerse(
      versesBucket,
      verseHolder.textContent
    );
    console.log(verse);

    hiddenVerseHolder.textContent = verse.verseFR  +  verse.verseEN;
    verseHolder.innerHTML += '<div class="verse-fr">' + verse.verseFR + '</div><br><div class="verse-fr">' + verse.verseEN + '</div>';

    document.querySelector(".verse-form").style.display = 'none';
    document.querySelector(".verse").style.display= "block";
    
    console.log("Verse from handler" + verse.verseFR);
    await sendVerseToEmail(email, verse, checkbox);

  } else {

    document.querySelector(".verse-form").style.display = 'block';
    document.querySelector(".verse").display= "none";

    emailInput.classList.add('error');
    emailError.style.visibility= "visible";

  }
}

// export async function handleClick() {
//   hiddenVerseHolder.textContent = getRandomVerse(
//       versesBucket,
//       verseHolder.textContent
//   ).item;
//   document.querySelector(".getVerse").style.visibility = "hidden";
//
// }
